import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { SbSharedRefineMenuFilterArtOrientation_itemSearch$key } from './__generated__/SbSharedRefineMenuFilterArtOrientation_itemSearch.graphql';
import { SbSharedRefineMenuFilters_filters$data } from './__generated__/SbSharedRefineMenuFilters_filters.graphql';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

// components
import { SbSharedRefineMenuMultiSelectList } from '../SbSharedRefineMenuMultiSelect/SbSharedRefineMenuMultiSelectList';

type Props = {
    itemSearch: SbSharedRefineMenuFilterArtOrientation_itemSearch$key;
    filterName: string;
    values: ArrayElement<SbSharedRefineMenuFilters_filters$data>['values'];
};

export const SbSharedRefineMenuFilterArtOrientation: FC<Props> = ({
    filterName,
    values,
    itemSearch: itemSearchRef,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterArtOrientation_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuMultiSelectList_itemSearch
            }
        `,
        itemSearchRef
    );

    return (
        <SbSharedRefineMenuMultiSelectList
            filterName={filterName}
            values={values}
            itemSearch={itemSearch}
        />
    );
};
